import { createAction } from "redux-act";
export const requestUnthldDetailProfile = createAction("REQUEST_UNTHLD_DETAIL_PROFILE_AUTO");
export const receiveUnthldDetailProfile = createAction("RECEIVE_UNTHLD_DETAIL_PROFILE_AUTO");
export const failUntHldDetailProfile = createAction("FAIL_UNTHLD_DETAIL__PROFILE_AUTO");
export const requestUntHldDetailDocuments = createAction("REQUEST_UNTHLD_DETAIL_DOCUMENTS_AUTO");
export const receiveUntHldDetailDocuments = createAction("RECEIVE_UNTHLD_DETAIL_DOCUMENTS_AUTO");
export const failUntHldDetailDocuments = createAction("FAIL_UNTHLD_DETAIL_DOCUMENTS_AUTO");
export const setCurrentCustomerUntHldDetail = createAction("SET_CURRENT_CUSTOMER_UNTHLD_DETAIL_AUTO");
export const requestUntHldDetailAccountLockedStatus = createAction(
  "REQUEST_UNTHLD_DETAIL_ACCOUNT_LOCKED_STATUS_AUTO"
);
export const receiveUntHldDetailAccountLockedStatus = createAction(
  "RECEIVE_UNTHLD_DETAIL_ACCOUNT_LOCKED_STATUS_AUTO"
);
export const failUntHldDetailAccountLockedStatus = createAction(
  "FAIL_UNTHLD_DETAIL_ACCOUNT_LOCKED_STATUS_AUTO"
);

export const requestUntHldDetailAccountUnlock = createAction(
  "REQUEST_UNTHLD_DETAIL_ACCOUNT_UNLOCK_AUTO"
);
export const receiveUntHldDetailAccountUnlock = createAction(
  "RECEIVE_UNTHLD_DETAIL_ACCOUNT_UNLOCK_AUTO"
);
export const failUntHldDetailAccountUnlock = createAction("FAIL_UNTHLD_DETAIL_ACCOUNT_UNLOCK_AUTO");

export const requestUntHldDetailDocumentApprove = createAction("REQUEST_UNTHLD_DETAIL_DOCUMENT_APPROVAL_AUTO");
export const receiveUntHldDetailDocumentApprove = createAction("RECEIVE_UNTHLD_DETAIL_DOCUMENT_APPROVAL_AUTO");
export const failUntHldDetailDocumentApprove = createAction("FAIL_UNTHLD_DETAIL_DOCUMENT_APPROVAL_AUTO");

export const requestUntHldDetailDocumentReject = createAction("REQUEST_UNTHLD_DETAIL_DOCUMENT_REJECTION_AUTO");
export const receiveUntHldDetailDocumentReject = createAction("RECEIVE_UNTHLD_DETAIL_DOCUMENT_REJECTION_AUTO");
export const failUntHldDetailDocumentReject = createAction("FAIL_UNTHLD_DETAIL_DOCUMENT_REJECTION_AUTO");

export const requestUntHldDetailDocumentUndo = createAction("REQUEST_UNTHLD_DETAIL_DOCUMENT_UNDO_AUTO");
export const receiveUntHldDetailDocumentUndo = createAction("RECEIVE_UNTHLD_DETAIL_DOCUMENT_UNDO_AUTO");
export const failUntHldDetailDocumentUndo = createAction("FAIL_UNTHLD_DETAIL_DOCUMENT_UNDO_AUTO");

export const requestUntHldDetailApprove = createAction("REQUEST_UNTHLD_DETAIL_APPROVAL_AUTO");
export const receiveUntHldDetailApprove = createAction("RECEIVE_UNTHLD_DETAIL_APPROVAL_AUTO");
export const failUntHldDetailApprove = createAction("FAIL_UNTHLD_DETAIL_APPROVAL_AUTO");

export const requestUntHldDetailReject = createAction("REQUEST_UNTHLD_DETAIL_REJECTION_AUTO");
export const receiveUntHldDetailReject = createAction("RECEIVE_UNTHLD_DETAIL_REJECTION_AUTO");
export const failUntHldDetailReject = createAction("FAIL_UNTHLD_DETAIL_REJECTION_AUTO");

export const requestUntHldDetailCancelStatus = createAction("REQUEST_UNTHLD_DETAIL_CANCEL_STATUS_AUTO");
export const receiveUntHldDetailCancelStatus = createAction("RECEIVE_UNTHLD_DETAIL_CANCEL_STATUS_AUTO");
export const failUntHldDetailCancelStatus = createAction("FAIL_UNTHLD_DETAIL_CANCEL_STATUS_AUTO");

export const requestUntHldDetailFunds = createAction("REQUEST_UNTHLD_DETAIL_FUNDS_AUTO");
export const receiveUntHldDetailFunds = createAction("RECEIVE_UNTHLD_DETAIL_FUNDS_AUTO");
export const failUntHldDetailFunds = createAction("FAIL_UNTHLD_DETAIL_FUNDS_AUTO");

export const clearUntHldDetailErrorMessage = createAction("CLEAR_UNTHLD_DETAIL_ERROR_MESSAGE_AUTO");
export const receiveCustomerUntHldDetailChannel = createAction("CUSTOMER_UNTHLD_DETAIL_CHANNEL_AUTO");
// -----------------------UserAccount----------------------
export const REQUsrActSrch = createAction("REQUEST_USRACT_SRCH_AUTO");
export const RESUsrActSrch = createAction("RECEIVE_USRACT_SRCH_AUTO");
export const FailUsrActSrch = createAction("FAILED_USRACT_SRCH_AUTO");
// -----------------------UnitHolder----------------------
export const REQUntHldSrch = createAction("REQUEST_UNTHLD_SRCH_AUTO");
export const RESUntHldSrch = createAction("RECEIVE_UNTHLD_SRCH_AUTO");
export const FailUntHldSrch = createAction("FAILED_UNTHLD_SRCH_AUTO");
export const CleanUpUntHldSrch = createAction("CLEANUP_UNTHLD_SRCH_AUTO");
export const UpdateUnitHolderId = createAction("UPDATE_UNIT_HOLDER_ID_AUTO");
export const FullImagePath = createAction("GET_FULL_IMAGE_AUTO")


export const getApplicants = createAction("GET_UNTHLD_APPLICANTS_AUTO");
export const setApplicants = createAction("SET_UNTHLD_APPLICANTS_AUTO");
export const failApplicants = createAction("FAIL_UNTHLD_APPLICANTS_AUTO");

export const setFullImgUrl = createAction("SET_FULL_IMG_URL_AUTO");
// regis img
export const FETCH_REGISTERED_IMAGE_REQUEST = createAction('FETCH_REGISTERED_IMAGE_REQUEST_AUTO');
export const FETCH_REGISTERED_IMAGE_SUCCESS = createAction('FETCH_REGISTERED_IMAGE_SUCCESS_AUTO');
export const FETCH_REGISTERED_IMAGE_FAILURE = createAction('FETCH_REGISTERED_IMAGE_FAILURE_AUTO');
// counter service
export const FETCH_COUNTERSERVICE_IMAGE_REQUEST = createAction('FETCH_COUNTERSERVICE_IMAGE_REQUEST_AUTO');
export const FETCH_COUNTERSERVICE_IMAGE_SUCCESS = createAction('FETCH_COUNTERSERVICE_IMAGE_SUCCESS_AUTO');
export const FETCH_COUNTERSERVICE_IMAGE_FAILURE = createAction('FETCH_COUNTERSERVICE_IMAGE_FAILURE_AUTO');
// s3
export const FETCH_UPLOAD_FILE_TO_S3_SUCCESS = createAction('FETCH_UPLOAD_FILE_TO_S3_SUCCESS_AUTO');
export const FETCH_UPLOAD_FILE_TO_S3_FAILURE = createAction('FETCH_UPLOAD_FILE_TO_S3_FAILURE_AUTO');
export const FETCH_UPLOAD_FILE_TO_S3_REQUEST = createAction('FETCH_UPLOAD_FILE_TO_S3_REQUEST_AUTO');
// save s3 to db
export const FETCH_SAVE_TO_DB_SUCCESS = createAction('FETCH_SAVE_TO_DB_SUCCESS_AUTO');
export const FETCH_SAVE_TO_DB_FAILURE = createAction('FETCH_SAVE_TO_DB_FAILURE_AUTO');
export const FETCH_SAVE_TO_DB_REQUEST = createAction('FETCH_SAVE_TO_DB_REQUEST_AUTO');
