import React from "react";
import Images from "../../utils/styles/images";

const docStatus = {
  PENDING: 1,
  APPROVED: 2,
  REJECTED: 3,
  IN_REVIEW: 4
};

const accStatus = {
  PENDING: 1,
  APPROVED: 2,
  REJECTED: 3,
  IN_REVIEW: 4,
  CREATING: 5,
  JUST_CONVERT_FROM_EXISTING: 6
};

export function modifyCell(cell, pageSelect, pageSize, handleActionClick) {
  // Format the cell data here
  const { props } = cell;
  let columnName =
    props && props.cell && props.cell.column && props.cell.column.Header
      ? props.cell.column.Header
      : null;
  let cellValue =
    props && props.cell && props.cell.value ? props.cell.value : null;
  let rowNumber = props && props.row.index + 1;
  let rowData =
    props && props.row && props.row.original ? props.row.original : null;

  if (columnName === "NO.") {
    if (pageSelect !== 0) {
      return rowNumber + pageSelect * 10;
    } else {
      return rowNumber;
    }
  } else if (columnName === "CONTACT NO.") {
    if (cellValue) {
      return `+ ${cellValue}`;
    } else {
      return cell;
    }
  } else if (columnName === "CONVERTED USER") {
    if (props.cell.value) {
      return "Yes";
    } else {
      return "No";
    }
  } else if (columnName === "REGISTER DATE") {
    if (cellValue) {
      const dateObject = new Date(cellValue);
      dateObject.setHours(dateObject.getHours());
      const tDate = `${dateObject.toISOString().slice(0, 19).replace("T", " ")}`;
      return tDate;
    } else {
      return cell;
    }
  } else if (columnName === "SUBMISSION DATE" || columnName === "LAST REVIEW DATE" || columnName === "UPDATED AT") {
    if (cellValue) {
      let tDate = "";
      let aDate1;
      aDate1 = cellValue.split("T");
      tDate = aDate1[0] + " " + aDate1[1].substring(0, 8);
      return tDate;
    } else {
      return "-";
    }
    
  } else if (columnName === "ENHANCED KYC") {
        if (props.value === true) {
      return "Yes";
    } else if (props.value === false) {
      return "No";
    } else {
      return "-";
    }
  } else if (columnName === "ACC. STATUS") {
    if (cellValue === 1) {
      return (
        <div className="clients-table-cell-account-status">
          <img src={Images.statusIcon.pending} alt="pending-icon" />
          <p className="clients-table-cell-account-status-pending">Pending</p>
        </div>
      );
    } else if (cellValue === 2) {
      return (
        <div className="clients-table-cell-account-status">
          <img src={Images.statusIcon.approved} alt="approved-icon" />
          <p className="clients-table-cell-account-status-approved">Approved</p>
        </div>
      );
    } else if (cellValue === 3) {
      return (
        <div className="clients-table-cell-account-status">
          <img src={Images.statusIcon.rejected} alt="pending-icon" />
          <p className="clients-table-cell-account-status-rejected">Rejected</p>
        </div>
      );
    } else if (cellValue === 4) {
      return (
        <div className="clients-table-cell-account-status">
          <img src={Images.statusIcon.pending} alt="pending-icon" />
          <p className="clients-table-cell-account-status-pending">In-Review</p>
        </div>
      );
    } else if (cellValue === 5) {
      return (
        <div className="clients-table-cell-account-status">
          <img src={Images.statusIcon.pending} alt="pending-icon" />
          <p className="clients-table-cell-account-status-pending">Creating</p>
        </div>
      );
    } else if (cellValue === 6) {
      return (
        <div className="clients-table-cell-account-status">
          <img src={Images.statusIcon.pending} alt="pending-icon" />
          <p className="clients-table-cell-account-status-pending">
            Just Convert
          </p>
        </div>
      );
    } else {
      return "-";
    }
  } else if (columnName === "DOC. STATUS") {
    if (cellValue === 1) {
      return (
        <div className="clients-table-cell-document-status">
          <img src={Images.statusIcon.pending} alt="pending-icon" />
          <p className="clients-table-cell-document-status-pending">Pending</p>
        </div>
      );
    } else if (cellValue === 2) {
      return (
        <div className="clients-table-cell-document-status">
          <img src={Images.statusIcon.approved} alt="success-icon" />
          <p className="clients-table-cell-document-status-approved">
            Approved
          </p>
        </div>
      );
    } else if (cellValue === 3) {
      return (
        <div className="clients-table-cell-document-status">
          <img src={Images.statusIcon.rejected} alt="rejected-icon" />
          <p className="clients-table-cell-document-status-rejected">
            Rejected
          </p>
        </div>
      );
    } else if (cellValue === 4) {
      return (
        <div className="clients-table-cell-document-status">
          <img src={Images.statusIcon.pending} alt="review-icon" />
          <p className="clients-table-cell-document-status-in-review">
            In-Review
          </p>
        </div>
      );
    } else {
      return "-";
    }
  }
  else if (columnName === "RISK LEVEL") {
    return cellValue || "-"
  }
  else if (columnName === "EMAIL") {
    return cellValue || "-"
  }
  else if (columnName === "BANK STATUS") {
    if (cellValue === 1) {
      return (
        <div className="clients-table-cell-document-status">
          <img src={Images.statusIcon.pending} alt="pending-icon" />
          <p className="clients-table-cell-document-status-pending">Pending</p>
        </div>
      );
    }
    else if (cellValue === 2) {
      return (
        <div className="clients-table-cell-document-status">
          <img src={Images.statusIcon.approved} alt="success-icon" />
          <p className="clients-table-cell-document-status-approved">
            Approved
          </p>
        </div>
      );
    }
    else if (cellValue === 3) {
      return (
        <div className="clients-table-cell-document-status">
          <img src={Images.statusIcon.rejected} alt="rejected-icon" />
          <p className="clients-table-cell-document-status-rejected">
            Rejected
          </p>
        </div>
      );
    }
  }
  else if (columnName === "UNITHOLDER ID") {
    if (cellValue) {
      return cellValue;
    } else {
      return "-";
    }
  } 
  else if (columnName === "FIRST NAME (EN)") {
    return cellValue || "-"    
  }

  else if (columnName === "LAST NAME (EN)") {
    return cellValue || "-"
  }
  else if (columnName === "FIRST NAME (TH)") {
    return cellValue || "-"
  }
  else if (columnName === "LAST NAME (TH)") {
    return cellValue || "-"
  }
  else if (columnName === "ACTION") {
    const { document_status, status_code } = rowData;
    
    if (
      document_status === docStatus.APPROVED &&
      status_code === accStatus.APPROVED
    ) {
      return (
        <button
          onClick={() => handleActionClick(rowData, "DISAPPROVE")}
          className="clients-table-cell-action"
        >
          Disapprove
        </button>
      );
    } else if (status_code === accStatus.PENDING) {
      return (
        <div>
          <button
            onClick={() => handleActionClick(rowData, "APPROVE")}
            className="clients-table-cell-action"
          >
            Approve
          </button>
          <button
            onClick={() => handleActionClick(rowData, "REJECT")}
            className="clients-table-cell-action"
          >
            Reject
          </button>
        </div>
      );
    } else if (status_code === accStatus.REJECTED) {
      return (
        <button
          onClick={() => handleActionClick(rowData, "CANCEL")}
          className="clients-table-cell-action"
        >
          Cancel
        </button>
      );
    } else if (status_code === accStatus.CREATING) {
      return (
        <button
          onClick={() => handleActionClick(rowData, "CREATING")}
          className="clients-table-cell-action"
        >
          CREATING
        </button>
      );
    } else {
      return null;
    }
  } else {
    return cell;
  }
}

// This is just for the header cells
export function modifyCellHeader(
  cell,
  handleActionHeaderClick,
  handleFltHeader
) {
  if (
    cell === "DOC. STATUS" ||
    cell === "ACC. STATUS" ||
    cell === "CONVERTED USER"
  ) {
    return handleFltHeader ? handleFltHeader(cell) : cell;
  }
  else if (cell === "SUBMISSION DATE") 
    {
      return (
        <button className="transactions-header-with-sort" 
         onClick={() => handleActionHeaderClick(cell)}
        >
          SUBMISSION DATE
          <img src={Images.sorting.sorting} alt="sorting-icon" />
        </button>
      );
    }
    else if (cell === "UPDATED AT") 
      {
        return (
          <button className="transactions-header-with-sort" 
           onClick={() => handleActionHeaderClick(cell)}
          >
            UPDATED AT
            <img src={Images.sorting.sorting} alt="sorting-icon" />
          </button>
        );
      } else {
    return cell;
  }
}
